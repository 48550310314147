import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '~contexts/AppContext';
import getSearchParams from '~utils/getSearchParam';
import EmptyView from '../../../components/EmptyView';
import LoadingView from '../../../components/LoadingView';
import OrderStatusRow from '../../../components/OrderStatusRow';
import { GET_ORDER_STATUS } from '../../../graphql/queries';
import { OrderStatusTypes } from '../../../types/orderTypes';
import { Routes } from '../../../types/routes';
import { timeConverter, timeFromNow } from '../../../utils/formatHelper';
import { dynamicNavigate } from '../../../utils/routeHelper';
import { getAppStateId } from '../../../utils/webIdUtils';
import { localstorageKeys } from '../../../types';
import { getLocalStorageValue } from '../../../utils/localstorageHelper';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 16,
  },
  line: {
    width: '1px',
    height: '48px',
    opacity: 0.3,
    margin: '8px 0px 8px 9px',
    backgroundColor: '#000000',
  },
  nestedContainer: {
    padding: '30px'
  }
});

const OrderStatus = ({ location }) => {
  
  const styles = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState(null);
  const [timer, setTimer] = useState(null);
  
  const { restaurantId, customerId } = useContext(
    AppContext
  );
  
  const [preparing, setPreparing] = useState(false);

  const [done, setDone] = useState(false);

  const { currentUser } = useContext(AppContext)

  const { token } = getLocalStorageValue(localstorageKeys.token, '');

  const id = getAppStateId(customerId);

  const { data, loading, error, refetch } = useQuery(
    GET_ORDER_STATUS,
    {
      context: {
        headers: {
          authorization: `Bearer ${(token) ? token : currentUser?.accessToken}`,
        },
      },
      variables: {
        restaurantId,
        customerId: id || currentUser?.customerId,
      },
    }
  );

  const iterate = () => {
    let cancel = setTimeout(async () => {
      let { data: newData } = await refetch()
      if (location && newData) {
        const id = getSearchParams('orderId');
        const order = newData.ordersStatus.find(
          order => order.id === Number(id)
        );
        setOrder(order);
        handleStatusUpdate(order);
      }
      iterate()
    }, 60000)
    return () => clearTimeout(cancel)
  }

  useEffect(() => {
    iterate()
  }, [])

  useEffect(() => {
    if (location && data) {
      const id = getSearchParams('orderId');
      const order = data.ordersStatus.find(
        order => order.id === Number(id)
      );
      setOrder(order);
      handleStatusUpdate(order);
    }
  }, [data])

  const handleStatusUpdate = order => {
    if (order?.foodStatus === OrderStatusTypes.ORDERED && !!!order?.restaurant?.isManualOrder) {
      setPreparing(true);
      let time = order?.restaurant?.preparationTimes?.find(it => it?.deliveryType === order?.deliveryType)
      if (time && time?.time && timeFromNow(order?.createdAt, time.time).length == 0) {
        setDone(true);
        setTimer(null)
      } else if (time && time?.time) {
        setTimer(timeFromNow(order?.createdAt, time.time))
        setDone(false)
      }
    }
    if (
      order?.foodStatus === OrderStatusTypes.PREPARING &&
      !preparing
    ) {
      setPreparing(true);
    }

    if (order?.foodStatus === OrderStatusTypes.DONE && !!order?.restaurant?.isManualOrder) {
      setPreparing(true);
      setDone(true);
    }
  };

  const handleEmptyView = () =>
    dynamicNavigate(Routes.FOOD);

  if (loading) return <LoadingView />;

  if (!order && error)
    return (
      <EmptyView
        onClick={handleEmptyView}
        title={t('SomethingWentWrong')}
        buttonTitle={t('GoBackToMenu')}
      />
    );

  const DELIVERY_TEXT = {
    "TAKEAWAY": t('OrderStatusDonePickup'),
    "DELIVERY": t('OrderStatusDoneDelivery'),
    "RESERVATION": t('OrderStatusDoneTable')
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.nestedContainer}>
        <Box className={styles.title}>
          <Typography>{t('ActiveOrderTitle')}</Typography>

        </Box>
        <OrderStatusRow
          title={`${t('OrderStatusOrdered')}`}
          caption={timeConverter(order?.createdAt)}
          isComplete={true}
        />
        <Box className={styles.line} />
        <OrderStatusRow
          title={t('OrderStatusPrepared')}
          isComplete={preparing}
          caption={timer ? t('OrderReadyToCollect') : ''}
          time={timer}
        />
        <Box className={styles.line} />
        <OrderStatusRow
          title={DELIVERY_TEXT[order?.deliveryType ?? "TAKEAWAY"]}
          isComplete={done}
        />
      </Box>
    </Box>
  );
};

export default OrderStatus;
